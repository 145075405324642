const CAMPAIGN_KEY = 'giftee_campaign'
const LANGUAGE_KEY = 'giftee_campaign_lang'
const SERIAL_CODE_VIA_SMS_KEY = 'giftee_campaign_serial_code_via_sms'

const getCampaign = () => {
  const dataCampaign = sessionStorage.getItem(CAMPAIGN_KEY)

  if (!dataCampaign) {
    return null
  }

  return JSON.parse(dataCampaign)
}

const setCampaign = (data) => {
  data = JSON.stringify({
    campaign_url: data.campaign_url,
    campaign_url_name: data.campaign_url_name,
    campaign_infos: data.campaign_infos,
    campaign_notes: data.campaign_notes,
    campaign_settings: data.campaign_settings,
    campaign_country: data.campaign_country,
    is_great_eastern_campaign: data.is_great_eastern_campaign,
  })

  sessionStorage.setItem(CAMPAIGN_KEY, data)
}

const getCampaignUrl = () => {
  const campaign = getCampaign()

  return campaign ? campaign.campaign_url : null
}
const getCampaignUrlName = () => {
  const campaign = getCampaign()

  return campaign ? campaign.campaign_url_name : null
}

const getCampaignSettings = () => {
  const campaign = getCampaign()

  return campaign ? campaign.campaign_settings : null
}

const getCampaignInfos = () => {
  const campaign = getCampaign()

  return campaign ? campaign.campaign_infos : null
}

const isGreatEasternCampaign = () => {
  const campaign = getCampaign()

  if (campaign?.is_great_eastern_campaign) {
    return true;
  }

  return false;
}

const clearCampaign = () => {
  sessionStorage.removeItem(CAMPAIGN_KEY)
}

const clearCampaignLanguage = () => {
  sessionStorage.removeItem(LANGUAGE_KEY)
}

const getLanguage = () => {
  const campaignLang = sessionStorage.getItem(LANGUAGE_KEY)
  const defaultData = JSON.stringify({
    language_id: 1, // Default language id: English
    font_type: 'Montserrat'
  })

  if (!campaignLang) {
    return JSON.parse(defaultData)
  }

  return JSON.parse(campaignLang)
}

const setLanguage = (languageId, fontType) => {
  const data = JSON.stringify({
    language_id: languageId,
    font_type: fontType
  })
  sessionStorage.setItem(LANGUAGE_KEY, data)
}

const setSerialCodeViaSMS = (serialCode) => {
  sessionStorage.setItem(SERIAL_CODE_VIA_SMS_KEY, serialCode)
}

const getSerialCodeViaSMS = () => {
  const serialCode = sessionStorage.getItem(SERIAL_CODE_VIA_SMS_KEY)

  return serialCode || null;
}

const getLotteryCampaign = (lotteryUuid) => {
  const dataLotteryCampaign = sessionStorage.getItem(lotteryUuid)

  if (!dataLotteryCampaign) {
    return null
  }

  return JSON.parse(dataLotteryCampaign)
}

const setLotteryCampaign = (lotteryUuid, data) => {
  data = JSON.stringify({
    campaign_url: data.campaign_url,
    campaign_url_name: data.campaign_url_name,
    campaign_settings: data.campaign_settings,
    win_flag: data.win_flag
  })

  sessionStorage.setItem(lotteryUuid, data)
}

const clearLotteryCampaign = (lotteryUuid) => {
  sessionStorage.removeItem(lotteryUuid)
}

export default {
  getCampaign,
  setCampaign,
  getCampaignUrl,
  getCampaignUrlName,
  getCampaignSettings,
  getCampaignInfos,
  clearCampaign,
  clearCampaignLanguage,
  getLanguage,
  setLanguage,
  setSerialCodeViaSMS,
  getSerialCodeViaSMS,
  getLotteryCampaign,
  setLotteryCampaign,
  clearLotteryCampaign,
  isGreatEasternCampaign
}
